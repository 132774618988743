import { FC, useEffect, useState } from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Link, useNavigate } from "react-router-dom";
import PriceHistoryModal from "@/pages/ConciergeSale/requests/tabs/details/info/priceHistoryModal";
import AgreedPriceModal from "@/pages/ConciergeSale/requests/tabs/details/info/agreedPriceModal";
import checkIcon from "@/assets/icons/check.svg";
import { addComma } from "@/utils/number";
import {
  useGetPricingHistoryList,
  useGetSaleDetailV2,
  useRegisterCarPost
} from "@/services/api/sales";
import { useDispatch } from "react-redux";
import { setDealTab } from "@/store/slices/deal";
import { appUrl } from "@/utils/env";

interface Props {
  id: number;
  carStatus: string;
  priceHistory: string;
  agreedPrice: string;
  submitAds: string;
  submitDeal: string;
  openPrice: boolean;
  setOpenPrice: (value: boolean) => void;
  setAllAdsModal: (value: boolean) => void;
}

const CarStateStepper: FC<Props> = ({
  id,
  carStatus,
  priceHistory,
  agreedPrice,
  submitAds,
  submitDeal,
  openPrice,
  setOpenPrice,
  setAllAdsModal
}) => {
  const [openHistory, setOpenHistory] = useState(false);
  const { data, refetch } = useGetSaleDetailV2(Number(id));
  const { data: historyData } = useGetPricingHistoryList(Number(id), {
    enabled: !!id
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutateAsync: registerCarPostMutate } = useRegisterCarPost();

  useEffect(() => {
    refetch();
  }, [openHistory, openPrice]);

  const renderStatus = (type: string) => {
    return (
      <Box
        sx={{
          width: "20px",
          height: "20px",
          background: `${
            type === "complete" || type === "complete-no-tick"
              ? "#E0F4EA"
              : type === "inprogress"
              ? "#00A754"
              : type === "pending" && "#EEEEEE"
          }`,
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          mr: 1
        }}
        component="span"
      >
        {type === "complete" && <img src={checkIcon} alt="check-icon" />}
      </Box>
    );
  };

  const adsHandler = () => {
    if (submitAds === "complete") {
      setAllAdsModal(true);
    } else if (submitAds === "inprogress") {
      if (typeof data?.car_post_token === "string") {
        window.open(
          `${appUrl}/services/car-post-details-funnel/${data.car_post_token}/car-pictures?conciergeSaleId=${data.id}`,
          "_parent"
        );
      } else if (data) {
        registerCarPostMutate({
          brand_model: data.car_brand_model,
          city: data.city,
          color: data.car_color,
          usage: data.usage,
          year: Number(data.car_brand_model_year),
          conciergeSaleId: data.id
        }).then((res) => {
          window.open(
            `${appUrl}/services/car-post-details-funnel/${res.token}/car-pictures?conciergeSaleId=${data.id}`,
            "_parent"
          );
        });
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid #EBEBEB",
          mt: "10px",
          px: "24px",
          bgcolor: "#fff"
        }}
      >
        <List sx={{ py: 0 }}>
          <ListItem
            sx={{
              borderBottom: "1px solid #EBEBEB",
              p: 0,
              height: "48px"
            }}
          >
            <Link
              to={`/concierge-sale/requests/${id}/car-status`}
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                color: "#6E6E6E"
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
                component="div"
              >
                {renderStatus(carStatus)}
                وضعیت خودرو
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
                component="div"
              >
                <Typography variant="body1" fontSize="14px">
                  {carStatus === "complete" ? "تعیین شده" : "تعیین نشده"}
                </Typography>
                <KeyboardArrowLeftIcon />
              </Box>
            </Link>
          </ListItem>
          <ListItem
            sx={{
              borderBottom: "1px solid #EBEBEB",
              p: 0,
              height: "48px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: `${priceHistory === "pending" ? "#A9A8AA" : "#6E6E6E"}`
            }}
            onClick={
              priceHistory === "pending" || priceHistory === "inprogress"
                ? () => setOpenHistory(false)
                : () => setOpenHistory(true)
            }
          >
            {" "}
            <Box
              sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
              component="div"
            >
              {renderStatus(priceHistory)}
              قیمت گذاری
            </Box>
            <Box
              sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
              component="div"
            >
              <Typography variant="body1" fontSize="14px">
                {priceHistory === "complete" && historyData?.length
                  ? `${addComma(
                      Number(historyData[0]?.price_lower_bound)
                    )} تا ${addComma(
                      Number(historyData[0]?.price_upper_bound)
                    )}`
                  : priceHistory === "inprogress"
                  ? "در انتظار تعیین قیمت"
                  : priceHistory === "pending" &&
                    "در انتظار تعیین وضعیت خودرو..."}
              </Typography>
              <KeyboardArrowLeftIcon />
            </Box>{" "}
          </ListItem>
          <ListItem
            sx={{
              borderBottom: "1px solid #EBEBEB",
              p: 0,
              height: "48px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: `${agreedPrice === "pending" ? "#A9A8AA" : "#6E6E6E"}`
            }}
            onClick={
              agreedPrice === "pending"
                ? () => setOpenPrice(false)
                : () => setOpenPrice(true)
            }
          >
            {" "}
            <Box
              sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
              component="div"
            >
              {renderStatus(agreedPrice)}
              قیمت توافقی
            </Box>
            <Box
              sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
              component="div"
            >
              <Typography variant="body1" fontSize="14px">
                {agreedPrice === "pending"
                  ? "در انتظار قیمت‌گذاری..."
                  : agreedPrice === "inprogress"
                  ? "تعیین نشده"
                  : agreedPrice === "complete" &&
                    `${addComma(
                      Number(
                        data?.concierge_sale_price_agreement[0]
                          .advertisement_price
                      )
                    )} تومان`}
              </Typography>
              <KeyboardArrowLeftIcon />
            </Box>
          </ListItem>
          <ListItem
            sx={{
              borderBottom: "1px solid #EBEBEB",
              p: 0,
              height: "48px"
            }}
            onClick={() => {
              adsHandler();
            }}
          >
            <Box
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                color: `${submitAds === "pending" ? "#A9A8AA" : "#6E6E6E"}`
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
                component="div"
              >
                {renderStatus(submitAds)}
                ثبت آگهی
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
                component="div"
              >
                <Typography variant="body1" fontSize="14px">
                  {submitAds === "pending"
                    ? "در انتظار توافق قیمت..."
                    : submitAds === "inprogress"
                    ? "در انتظار ثبت آگهی"
                    : submitAds === "complete"
                    ? "ثبت شده"
                    : null}
                </Typography>
                <KeyboardArrowLeftIcon />
              </Box>
            </Box>
          </ListItem>
          {/* <ListItem
            sx={{
              borderBottom: "1px solid #EBEBEB",
              p: 0,
              height: "48px"
            }}
            onClick={() =>
              window.open(
                "https://karnameh.com/services/car-authentication/car-info",
                "_parent"
              )
            }
          >
            <Box
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                color: "#6E6E6E"
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
                component="div"
              >
                {renderStatus("complete-no-tick")}
                سوابق خودرو
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
                component="div"
              >
                <Typography variant="body1" fontSize="14px">
                  استعلام
                </Typography>
                <KeyboardArrowLeftIcon />
              </Box>
            </Box>
          </ListItem> */}
          <ListItem
            sx={{
              p: 0,
              height: "48px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: `${submitDeal === "pending" ? "#A9A8AA" : "#6E6E6E"}`
            }}
            onClick={
              submitDeal === "inprogress" || submitDeal === "complete"
                ? () => {
                    navigate(
                      `/concierge-sale/requests/${id}/submit-deals?car=${data?.car_brand_model}`
                    );
                    dispatch(setDealTab("deals"));
                  }
                : (event) => event.preventDefault()
            }
          >
            {" "}
            <Box
              sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
              component="div"
            >
              {renderStatus(submitDeal)}
              ثبت معامله و پرداخت ها
            </Box>
            <Box
              sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
              component="div"
            >
              <Typography variant="body1" fontSize="14px">
                {submitDeal === "pending"
                  ? "در انتظار ثبت آگهی..."
                  : submitDeal === "inprogress"
                  ? "در انتظار ثبت معامله"
                  : submitDeal === "complete"
                  ? "مشاهده"
                  : null}
              </Typography>
              <KeyboardArrowLeftIcon />
            </Box>
          </ListItem>
        </List>
      </Box>
      <PriceHistoryModal
        title="تاریخچه قیمت گذاری"
        open={openHistory}
        showModal={setOpenHistory}
      />
      {data?.id ? (
        <AgreedPriceModal
          title="قیمت توافقی"
          open={openPrice}
          onOpen={() => setOpenPrice(true)}
          onClose={() => setOpenPrice(false)}
          id={Number(data?.id)}
        />
      ) : null}
    </>
  );
};

export default CarStateStepper;
