type ClientKeys =
  | "API_2_URL"
  | "API_REST_URL"
  | "MY_CAR_API_URL"
  | "AFTERMARKET_API_URL"
  | "PRICE_ESTIMATOR_API_URL"
  | "SWITCH_BASE_URL"
  | "LEASING_BASE_URL"
  | "USER_TRACE_URL"
  | "POST_STORAGE_URL"
  | "AUTO_DETAIL_URL"
  | "CARMAA_URL"
  | "BOOGH_URL"
  | "PERSONAL_LISTING_URL"
  | "CLUTCH_URL"
  | "DEALERS_URL"
  | "BUCK";

export const servicesPathnames: { [key in ClientKeys]: string } = {
  API_2_URL: "/trade",
  API_REST_URL: "/rest/api",
  MY_CAR_API_URL: "/mycar/api",
  AFTERMARKET_API_URL: "/aftermarket/api",
  PRICE_ESTIMATOR_API_URL: "/priceestimator/api",
  SWITCH_BASE_URL: "/switch/api",
  USER_TRACE_URL: "/user-trace",
  LEASING_BASE_URL: "/leasing/api/v1",
  POST_STORAGE_URL: "/post-storage",
  AUTO_DETAIL_URL: "/autodetail",
  CARMAA_URL: "/carmaa",
  PERSONAL_LISTING_URL: "/personal-listing",
  BOOGH_URL: "/boogh",
  CLUTCH_URL: "/clutch",
  DEALERS_URL: "/dealers/api",
  BUCK: "/buck"
};

const switchBaseUrl = servicesPathnames.SWITCH_BASE_URL;
const userTraceBaseUrl = servicesPathnames.USER_TRACE_URL;
const api2BaseUrl = servicesPathnames.API_2_URL;
const restBaseUrl = servicesPathnames.API_REST_URL;
const leasingBaseUrl = servicesPathnames.LEASING_BASE_URL;
const postStorageUrl = servicesPathnames.POST_STORAGE_URL;
const booghBaseUrl = servicesPathnames.BOOGH_URL;
const dealersBaseUrl = servicesPathnames.DEALERS_URL;
const buckBaseUrl = servicesPathnames.BUCK;
// const aftermarketBaseUrl = servicesPathnames.AFTERMARKET_API_URL;
// const myCarBaseUrl = servicesPathnames.MY_CAR_API_URL;
// const priceEstimatorBaseUrl = servicesPathnames.PRICE_ESTIMATOR_API_URL;
// const autoDetailBaseUrl = servicesPathnames.AUTO_DETAIL_URL;
// const carmaaUrl = servicesPathnames.CARMAA_URL;
// const personalListingUrl = servicesPathnames.PERSONAL_LISTING_URL;
// const clutchBaseUrl = servicesPathnames.CLUTCH_URL;

export const switchEndpoints = {
  REFRESH_TOKEN: `${switchBaseUrl}/auth/refresh/`,
  SEND_OTP: `${switchBaseUrl}/auth/otp/send/`,
  CHECK_OTP: `${switchBaseUrl}/auth/otp/confirm/`,
  SERVICES_LIST: `${switchBaseUrl}/services/`,
  CURRENT_USER: `${switchBaseUrl}/users/current-user/`,
  UPLOAD_MEDIA: `${switchBaseUrl}/media/`
};

export const booghEndPoints = {
  SEEN_NOTIF: `${booghBaseUrl}/api/in_app_notif/:id/seen/`,
  IN_APP_NOTIF_LIMIT: `${booghBaseUrl}/api/in_app_notif/?app_id=P&limit=1`,
  IN_APP_NOTIF: `${booghBaseUrl}/api/in_app_notif/`,
  PUSH_TOKEN: `${booghBaseUrl}/api/push_token/`,
  INVALIDATE_PUSH_TOKEN: `${booghBaseUrl}/api/push_token/:deviceId/invalidate/`,
  NOTIFICATIONS_LIST: `${booghBaseUrl}/api/in_app_notif/`,
  READ_NOTIFICATIONS: `${booghBaseUrl}/api/in_app_notif/:notificationId/seen/`
};

export const buckEndpoints = {
  FINANCIAL_REPORT_LIST: `${buckBaseUrl}/api/expert/financial-report/`,
  FINANCIAL_REPORT_DETAIL: `${buckBaseUrl}/api/expert/financial-report/:id/details/`,
  FINANCIAL_PERFORMANCE_SERVICE_COUNT: `${buckBaseUrl}/api/expert/performance-report/count/`,
  FINANCIAL_PERFORMANCE_SERVICE_INCOME: `${buckBaseUrl}/api/expert/performance-report/income/`
};

export const postStorageEndPoints = {
  CAR_POST_STATES: `${postStorageUrl}/car-posts/states/`,
  CAR_POST_UPLOAD_FILE: `${postStorageUrl}/car-posts/funnel/upload-file/`
};

export const leasingEndpoints = {
  CITIES: `${leasingBaseUrl}/cities/info/`,
  RESUME_ORDER: `${leasingBaseUrl}/orders/backoffice/resume-order/:id/`,
  REJECT_ORDER: `${leasingBaseUrl}/orders/assistant/reject/`,
  PLANS: `${leasingBaseUrl}/providers/plans/`,
  PROVIDERS: `${leasingBaseUrl}/providers/`,
  INSTALMENT_CONDITIONS: `${leasingBaseUrl}/providers/:id/conditions/`,
  INSTALMENT_MONTH: `${leasingBaseUrl}/providers/instalment-months/`,
  REGISTER_ORDER: `${leasingBaseUrl}/orders/register/`,
  CUSTOMER_VALIDATION: `${leasingBaseUrl}/orders/customer-validation/`,
  UPLOAD_FILE: `${leasingBaseUrl}/common/files/`,
  ORDERS: `${leasingBaseUrl}/orders/assistant-orders/`,
  ORDER_DETAIL: `${leasingBaseUrl}/orders/assistant-orders/:id/`,
  ORDER_STATES: `${leasingBaseUrl}/orders/states/`,
  ORDER_ACTIONS: `${leasingBaseUrl}/orders/assistant-orders/:id/actions/`,
  EXECUTE_ACTION: `${leasingBaseUrl}/orders/:id/actions/:actionId/execute/`,
  ORDER_POS_PAYMENT: `${leasingBaseUrl}/orders/assistant-orders/:id/pose-payments/`,
  ORDER_POS_PAYMENT_IMAGE: `${leasingBaseUrl}/orders/assistant-orders/:id/pose-payments/:imageId/`,
  ORDER_NOTE: `${leasingBaseUrl}/notes/expert-notes/`,
  STAFF_AVAILABILITY_STATUS: `${leasingBaseUrl}/staff/availability/status/`,
  SET_STAFF_AVAILABILITY: `${leasingBaseUrl}/staff/availability/set-status/`,
  UPLOAD_DOC_SEND_SMS: `${leasingBaseUrl}/orders/assistant/upload-document-send-sms/`
};

export const userTraceEndpoints = {
  REGISTER: `${userTraceBaseUrl}/user-trace/v1/event_logs/register`,
  CAR_POST_ANALYTICS: `${userTraceBaseUrl}/user-trace/v1/analytics/car-post/:token/user-impact`
};

export const api2Endpoints = {
  COLORS: `${api2BaseUrl}/colors/`,
  PAYMENT: `${api2BaseUrl}`,
  PAYMENT_LIST: `${api2BaseUrl}/payment/v1/payments`,
  CREATE_PAYMENT: `${api2BaseUrl}/payment/v1/create_invoice`,
  LEASING_NOTE: `${api2BaseUrl}/note/v1/note/leasing/:uuid`,
  SEND_TO_PRICING: `${api2BaseUrl}/sales-expert/:conciergeSaleId/send-to-pricing/`,
  CAR_STATUS: `${api2BaseUrl}/car-health/:conciergeSaleId`,
  LOAN_CONFIG: `${api2BaseUrl}/leasing/v1/loan-config`,
  INVALIDATE_OFFER: `${api2BaseUrl}/supervisor/order/:conciergeSaleId/set-invalid`,
  SUPERVISOR_ORDER_LIST: `${api2BaseUrl}/supervisor/orders`,
  EXPERT_OFFER_LIST: `${api2BaseUrl}/sales-expert/offer/orders`,
  APPROVE_ORDER: `${api2BaseUrl}/supervisor/order/:id/approve`,
  SUPERVISOR_LIST: `${api2BaseUrl}/supervisor/sales-experts/all`,
  REVERT: `${api2BaseUrl}/concierge-sale/backoffice/revert`,
  AFFIDAVIT_UPLOAD: `${api2BaseUrl}/concierge-sale/affidavit/upload`,
  CHECK_REPETITIVE: `${api2BaseUrl}/concierge-sale/backoffice/:id/is-repetitive`,
  EXPENSE_REPORT: `${api2BaseUrl}/sales-expert/expense-report`,
  ASSISTANT_CONFIGS: `${api2BaseUrl}/cars/v1/configs/by-level-id/:levelId/:lastLevelId`,
  REGISTER_CAR_POST: `${api2BaseUrl}/concierge-sale/v2/car-post/:conciergeSaleId`,
  SALE_SHARE: `${api2BaseUrl}/sales-expert/:id/share`,
  PRICE_HISTORY: `${api2BaseUrl}/concierge-sale/backoffice/pricing/pricing-history/:id`,
  INSPECTION_DETAIL: `${api2BaseUrl}/inspection/:id`,
  EXPERT_TIME: `${api2BaseUrl}/evaluation/v1/sales-expert-time/`,
  CALCULATE_COMMISSION: `${api2BaseUrl}/concierge-sale/submit-deal/calculate-commission`,
  ATTACH_REPORT: `${api2BaseUrl}/concierge-sale/v2/car-post/:concierge_sale_id/:inspection_id/attach-report`,
  CAR_POST_DETAIL: `${api2BaseUrl}/concierge-sale/car-post/:id/car-post-detail`,
  SUBMIT_DIVAR: `${api2BaseUrl}/concierge-sale/car-post/:id/divar/submit`,
  EDIT_DIVAR: `${api2BaseUrl}/concierge-sale/car-post/:id/divar/edit`,
  LADDER_DIVAR: `${api2BaseUrl}/concierge-sale/car-post/:id/divar/ladder`,
  LADDER_HISTORY_DIVAR: `${api2BaseUrl}/concierge-sale/car-post/:id/divar/ladder-history`,
  SUBMIT_CAR_POST: `${api2BaseUrl}/concierge-sale/car-post/:id/submit`,
  CAR_POST: `${api2BaseUrl}/concierge-sale/car-post/:id`,
  CAR_UPLOAD_FILE: `${api2BaseUrl}/concierge-sale/car-post/upload-file`,
  SUBMIT_DEAL: `${api2BaseUrl}/concierge-sale/submit-deal/:id/`,
  CANCEL: `${api2BaseUrl}/concierge-sale/cancel/:id/`,
  SALES_REQUESTS: `${api2BaseUrl}/sales-expert/requests`,
  SALES_REQUEST_DETAIL: `${api2BaseUrl}/sales-expert/v2/requests/:id`,
  SALE_NOTE: `${api2BaseUrl}/note/v1/note/concierge-sale/:id/`,
  SALE_NOTE_REGISTER: `${api2BaseUrl}/note/v1/note/concierge-sale/register/`,
  SALE_STATUS: `${api2BaseUrl}/concierge-sale/:id`,
  CALCULATE_PRICE: `${api2BaseUrl}/negotiated-price/calculate/`,
  SUBMIT_PRICE: `${api2BaseUrl}/negotiated-price/submit/:id/`,
  CANCEL_REASONS: `${api2BaseUrl}/concierge-sale/cancel/cancel_reasons/`
};

export const carmaaEndPoints = {
  POST_CARMAA_BODY_FORM_INIT: `carmaa/api/v1/body/`,
  GET_CARMAA_BODY_FORM: `carmaa/api/v1/body/staff/:id/`,
  GET_CARMAA_BODY_FORM_PART: `carmaa/api/v1/body/staff/:id/:part/`,
  PUT_UPDATE_CARMAA_BODY_FORM: `carmaa/api/v1/body/staff/:id/`,
  PUT_UPDATE_CARMAA_BODY_FORM_PART: `carmaa/api/v1/body/staff/:id/:part/`,
  GET_CARMAA_BODY_INFO: `carmaa/api/v1/body/:id/info/`,
  GET_CARMAA_TECHNICAL_FORM: `carmaa/api/v1/technical/:id/`,
  PUT_CARMAA_TECHNICAL_FORM: `carmaa/api/v1/technical/:id/`,
  GET_CARMAA_TECHNICAL_INFO: `carmaa/api/v1/technical/:id/info/`
};

export const dealersEndPoints = {
  PLAN_LIST: `${dealersBaseUrl}/v1/plan-purchase/v2/plan/`,
  REGISTER: `${dealersBaseUrl}/v1/concierge-sale/expert-app/register`,
  OFFER_LIST: `${dealersBaseUrl}/v1/concierge-sale/expert-app/offers`,
  APPROVE_OFFER: `${dealersBaseUrl}/v1/concierge-sale/expert-app/offer-approve`,
  CANCEL_OFFER: `${dealersBaseUrl}/v1/concierge-sale/expert-app/cancel-offer-approve`
};

export const restEndpoints = {
  RRRR: `${restBaseUrl}`,
  SALE_PACKAGES: `${restBaseUrl}/evaluation/v1/sale-packages/`,
  VERIFY_SHIFT: `${restBaseUrl}/evaluation/v1/expert-verify-tomorrow-shifts/`,
  UPDATE_EXPERT_STATE: `${restBaseUrl}/evaluation/v1/update-expert-states/`,
  EXPERT_SHIFTS: `${restBaseUrl}/evaluation/v1/expert-weekly-shifts/`,
  SEARCH_CAR: `${restBaseUrl}/find_cars/v1/search`,
  SEARCH_EXACT_CAR: `${restBaseUrl}/find_cars/v1/search/exact/`,
  FETCH_CARS: `${restBaseUrl}/find_cars/v1/fetch_cars`,
  EXPERT_TIME: `${restBaseUrl}/evaluation/v1/sales-expert-time/`,
  OFFER_APPROVE: `${restBaseUrl}/inspection/v1/order/:offerId/approve`,
  OFFER_CANCEL: `${restBaseUrl}/inspection/v1/order/:offerId/cancel-approve`,
  EXPERT_OFFERS: `${restBaseUrl}/inspection/v1/expert/orders`,
  BACKOFFICE_INSPECTION_LIST: `${restBaseUrl}/evaluation/v1/backoffice/inspection-list/`,
  RESERVE: `${restBaseUrl}/evaluation/v1/expert/order/inspection-app/reserve/`,
  WORKLOAD: `${restBaseUrl}/evaluation/v1/expert/workload/`,
  INSPECTION_SECTION: `${restBaseUrl}/evaluation/v1/expert/inspection/:id/section/:section`,
  INSPECTION_SCHEMA: `${restBaseUrl}/evaluation/v1/expert/inspection/:id/schema/`,
  CREATE_REPORT: `${restBaseUrl}/evaluation/v1/expert/order/:orderId/create-report/`,
  UPDATE_SCHEMA: `${restBaseUrl}/evaluation/v1/expert/inspection/:orderId/schema/update/`,
  UPDATE_REPORT_SECTION: `${restBaseUrl}/evaluation/v1/expert/order/:orderId/update-report-section/`,
  MOVE_ORDER_STEP: `${restBaseUrl}/evaluation/v1/expert/order/:id/move-to-next-step/`,
  FETCH_DETAILS: `${restBaseUrl}/evaluation/v1/expert/order/:id/fetch-details/`,
  DONE_ORDERS: `${restBaseUrl}/evaluation/v1/expert/done-orders/`,
  FETCH_ASSIGNED_ORDERS: `${restBaseUrl}/evaluation/v1/expert/fetch-assigned-orders/`,
  FEEDBACK_RATES: `${restBaseUrl}/evaluation/v1/expert/feedback/rates/`,
  FEEDBACK_STAT: `${restBaseUrl}/evaluation/v1/expert/feedback/stats/`,
  FEEDBACK_COMMENTS: `${restBaseUrl}/evaluation/v1/expert/feedback/comments/`,
  CMS_DETAIL: `${restBaseUrl}/cms/contents/:id/`,
  CHECK_CODE: `${restBaseUrl}/discount/v1/check_code/`,
  REPORT_DIFFERENCES: `${restBaseUrl}/evaluation/v1/inspection/report-differences/`,
  TICKET_LIST: `${restBaseUrl}/evaluation/v1/inspection-ticket/`,
  INSPECTION_TICKET: `${restBaseUrl}/evaluation/v1/inspection-ticket/:id/`,
  REFUND_PENALTY: `${restBaseUrl}/evaluation/v1/backoffice/refund-after-cancel-inspection/:id`,
  ANONYMIZE_IMAGE: `${restBaseUrl}/evaluation/v1/backoffice/inspection/:inspectionId/anonymize-images/`,
  INSPECTION_DETAIL: `${restBaseUrl}/evaluation/v1/backoffice/inspection/:inspectionId/`,
  UPDATE_INSPECTION: `${restBaseUrl}/evaluation/v1/backoffice/inspection/:inspectionId/update/`,
  BACKOFFICE_INSPECTION_SECTION: `${restBaseUrl}/evaluation/v1/backoffice/inspection/:inspectionToken/section/:section`,
  BACKOFFICE_INSPECTION_SCHEMA: `${restBaseUrl}/evaluation/v1/backoffice/inspection/:inspectionToken/schema/`,
  CREATE_INSPECTION: `${restBaseUrl}/evaluation/v1/backoffice/inspection/create/`,
  INSPECTION_CENTER: `${restBaseUrl}/evaluation/v1/inspection-center/`,
  TIME_SLOT_AVAILABILITY: `${restBaseUrl}/evaluation/v1/timeslot_availability/`,
  SERVICE_TIME: `${restBaseUrl}/evaluation/v1/service_time/`,
  PACKAGE_LIST: `${restBaseUrl}/evaluation/v1/packages/`
};

export const aftermarketEndpoints = {};

export const myCarEndpoints = {};

export const priceEstimatorEndpoints = {};

export const personalListingEndPoints = {};

export const autoDetailEndPoints = {};

export const clutchEndPoints = {};
