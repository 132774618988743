import { httpAPIGW } from "@/services/http-clients";
import { api2Endpoints } from "@/services/http-clients/configs";
import { endPointUrlNormalizer } from "@/services/http-clients/utils";
import {
  MutationFunction,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from "react-query";

//#region Get All Supervisor Sale Experts

export interface GetAllSupervisorSaleExpertsResponse {
  id: number;
  name: string;
  active_leads: number;
  last_30_days_sale_total: number;
  last_30_days_sale_count: number;
  last_30_days_sale_average: number;
}

const getAllSupervisorSaleExperts = async () => {
  return httpAPIGW<GetAllSupervisorSaleExpertsResponse[]>({
    method: "GET",
    url: api2Endpoints.SUPERVISOR_LIST
  }).then((res) => res.data);
};

const getAllSupervisorSaleExpertsKey = "getAllSupervisorSaleExperts";

export const useGetAllSupervisorSaleExperts = (
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getAllSupervisorSaleExperts>>,
    // unknown,
    unknown,
    Awaited<ReturnType<typeof getAllSupervisorSaleExperts>>
  >
) => {
  return useQuery({
    queryKey: [getAllSupervisorSaleExpertsKey],
    staleTime: 0,
    queryFn: () => {
      return getAllSupervisorSaleExperts();
    },
    ...options
  });
};

//#endregion

//#region POST Supervisor Accept Order

interface PostSupervisorAcceptOrderBody {
  sales_expert_id?: number;
}

const postSupervisorAcceptOrder = async (
  id: number,
  data: PostSupervisorAcceptOrderBody
) => {
  return httpAPIGW({
    method: "POST",
    url: endPointUrlNormalizer(api2Endpoints.APPROVE_ORDER, { id: `${id}` }),
    data
  });
};

const postSupervisorAcceptOrderKey = "postSupervisorAcceptOrder";

export const usePostSupervisorAcceptOrder = (id: number) => {
  return useMutation({
    mutationKey: [postSupervisorAcceptOrderKey, id],
    mutationFn: (data: PostSupervisorAcceptOrderBody) => {
      return postSupervisorAcceptOrder(id, data);
    }
  });
};

//#endregion

//#region Get Expert Offer List

export interface GetOfferListResponse {
  order_id: number;
  car_brand_model: string;
  car_brand_model_fa: string;
  car_brand_model_year: number;
  usage: number;
  car_color: string;
  address: string;
  city: string;
}

const getExpertOfferList = async () => {
  return httpAPIGW<GetOfferListResponse[]>({
    method: "GET",
    url: api2Endpoints.EXPERT_OFFER_LIST
  }).then((res) => res.data);
};

const getExpertOfferListKey = "getExpertOfferList";

export const useGetExpertOfferList = (
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getExpertOfferList>>,
    // unknown,
    unknown,
    Awaited<ReturnType<typeof getExpertOfferList>>
  >
) => {
  return useQuery({
    queryKey: [getExpertOfferListKey],
    staleTime: 0,
    refetchInterval: 30_000,
    queryFn: () => {
      return getExpertOfferList();
    },
    ...options
  });
};
//#endregion

//#region Get Supervisor Offers List

const getOfferList = async () => {
  return httpAPIGW<GetOfferListResponse[]>({
    method: "GET",
    url: api2Endpoints.SUPERVISOR_ORDER_LIST
  }).then((res) => res.data);
};

const getOfferListKey = "getOfferList";

export const useGetOfferList = (
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getOfferList>>,
    // unknown,
    unknown,
    Awaited<ReturnType<typeof getOfferList>>
  >
) => {
  return useQuery({
    queryKey: [getOfferListKey],
    staleTime: 0,
    refetchInterval: 30_000,
    queryFn: () => {
      return getOfferList();
    },
    ...options
  });
};

//#endregion

//#endregion invalidOffer
interface PostInvalidOfferParams {
  conciergeSaleId: number;
  note: string;
}

export const postInvalidOffer = ({
  conciergeSaleId,
  note
}: PostInvalidOfferParams) => {
  return httpAPIGW({
    url: endPointUrlNormalizer(api2Endpoints.INVALIDATE_OFFER, {
      conciergeSaleId: `${conciergeSaleId}`
    }),
    method: "POST",
    data: {
      note
    }
  });
};

export const usePostInvalidOffer = (
  options?: UseMutationOptions<unknown, unknown, PostInvalidOfferParams>
) => {
  const mutationFn: MutationFunction<unknown, PostInvalidOfferParams> = (
    params
  ) => {
    return postInvalidOffer(params);
  };

  return useMutation({
    mutationFn,
    ...options
  });
};
//#endregion
