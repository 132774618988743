import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from "react-query";
import { httpAPIGW } from "../http-clients";
import { booghEndPoints } from "../http-clients/configs";
import { endPointUrlNormalizer } from "../http-clients/utils";

// GET One Notif
export type InAppNotif = {
  id: number;
  title: string;
  seen: boolean;
  content: string;
  created_at: string;
};

export type InAppNotifResponse = {
  results: InAppNotif[];
  count: number;
};

export const getInAppNotifURL = booghEndPoints.IN_APP_NOTIF_LIMIT;

export const getInAppNotif = async () => {
  const { data } = await httpAPIGW<InAppNotifResponse>({
    method: "GET",
    url: getInAppNotifURL
  });

  return data;
};

export const getInAppNotifKey = () => [getInAppNotifURL];

type GetInAppNotifUseQueryOptions = Omit<
  UseQueryOptions<InAppNotifResponse, unknown, InAppNotifResponse, string[]>,
  "queryKey" | "queryFn"
>;

export const useGetInAppNotif = (options?: GetInAppNotifUseQueryOptions) => {
  return useQuery({
    queryKey: getInAppNotifKey(),
    queryFn: getInAppNotif,
    refetchInterval: 15 * 60 * 1000, // 15 minutes in miliseconds
    ...options
  });
};

// GET All Notif
export type InAppAllNotif = {
  id: number;
  title: string;
  seen: boolean;
  content: string;
  created_at: string;
};

export type InAppAllNotifResponse = {
  count: number;
  results: InAppAllNotif[];
};

export const getInAppAllNotifURL = booghEndPoints.IN_APP_NOTIF;

export const getInAppAllNotif = async () => {
  const { data } = await httpAPIGW<InAppAllNotifResponse>({
    method: "GET",
    url: getInAppAllNotifURL,
    params: { limit: 20 }
  });

  return data;
};

export const getInAppAllNotifKey = () => [getInAppAllNotifURL];

type GetInAppAllNotifUseQueryOptions = Omit<
  UseQueryOptions<
    InAppAllNotifResponse,
    unknown,
    InAppAllNotifResponse,
    string[]
  >,
  "queryKey" | "queryFn"
>;

export const useGetInAppAllNotif = (
  options?: GetInAppAllNotifUseQueryOptions
) => {
  return useQuery({
    queryKey: getInAppAllNotifKey(),
    queryFn: getInAppAllNotif,
    refetchInterval: 10 * 60 * 1000, // 15 minutes in miliseconds
    ...options
  });
};

// POST assigned order to next step
export interface PostSeenNotifRequest {
  id: string;
}

export const PostSeenNotifURL = (id: string) =>
  endPointUrlNormalizer(booghEndPoints.SEEN_NOTIF, { id });

export const postSeenNotif = async (req: PostSeenNotifRequest) => {
  const { data } = await httpAPIGW({
    method: "POST",
    url: PostSeenNotifURL(req.id)
  });

  return data;
};

type PostSeenNotifOptions = Omit<
  UseMutationOptions<unknown, unknown, PostSeenNotifRequest, unknown>,
  "mutateFn"
>;

export const usePostSeenNotif = (options?: PostSeenNotifOptions) => {
  return useMutation({ mutationFn: postSeenNotif, ...options });
};

//#region Push Token

interface PushTokenParams {
  token: string;
  device_id: string;
  app_id: "M" | "P" | "B" | "PW"; // M => mycar, P => provider-app, B => backoffice, PW => provider-web
}

interface PushTokenResponse {
  id: number;
  token: string;
}

const pushToken = (data: PushTokenParams) => {
  return httpAPIGW<PushTokenResponse>({
    method: "POST",
    url: booghEndPoints.PUSH_TOKEN,
    data
  });
};

export const usePushToken = () => {
  return useMutation({
    mutationFn: (data: PushTokenParams) => {
      return pushToken(data);
    }
  });
};

//#endregion
