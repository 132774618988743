import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBsfgxfdmRq30EMKdBYrXNrnRDCkSLMr3M",
  authDomain: "provider-app-e1799.firebaseapp.com",
  projectId: "provider-app-e1799",
  storageBucket: "provider-app-e1799.firebasestorage.app",
  messagingSenderId: "1070714979429",
  appId: "1:1070714979429:web:3dcdcb80952c142c1fb28c",
  measurementId: "G-LNCZLZLKJT"
};

const firebaseApp = initializeApp(firebaseConfig);

let messaging: Messaging | null = null;
if (typeof window !== "undefined") {
  messaging = getMessaging(firebaseApp);
}

async function requestPermission() {
  console.log("Requesting permission...");
  if (!("Notification" in window)) {
    console.error("This browser does not support notifications.");
    return false;
  }

  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      return true;
    } else {
      console.warn("Notification permission denied.");
      return false;
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
    return false;
  }
}

export { messaging, getToken, onMessage, requestPermission };
