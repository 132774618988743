import { AxiosError } from "axios";
import httpClientCreator from "./http-client-creator";

export const baseUrlApiGW = import.meta.env.VITE_BASE_URL_API_GW;

export const httpAPIGW = httpClientCreator(baseUrlApiGW);

export type ErrorType<Error> = AxiosError<Error>;

export const baseUrls = {
  //API_GW
  BASE_URL_API_GW_MAIN: import.meta.env.VITE_BASE_URL_API_GW_MAIN,
  BASE_URL_API_GW_TEST_1: import.meta.env.VITE_BASE_URL_API_GW_TEST_1,
  BASE_URL_API_GW_TEST_2: import.meta.env.VITE_BASE_URL_API_GW_TEST_2,
  BASE_URL_API_GW_TEST_3: import.meta.env.VITE_BASE_URL_API_GW_TEST_3,
  BASE_URL_API_GW_TEST_4: import.meta.env.VITE_BASE_URL_API_GW_TEST_4,
  BASE_URL_API_GW_TEST_5: import.meta.env.VITE_BASE_URL_API_GW_TEST_5,
  BASE_URL_API_GW_TEST_6: import.meta.env.VITE_BASE_URL_API_GW_TEST_6,
  BASE_URL_API_GW_TEST_7: import.meta.env.VITE_BASE_URL_API_GW_TEST_7,
  BASE_URL_API_GW_TEST_8: import.meta.env.VITE_BASE_URL_API_GW_TEST_8,
  BASE_URL_API_GW_TEST_9: import.meta.env.VITE_BASE_URL_API_GW_TEST_9
};
