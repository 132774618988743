import { useState, useEffect } from "react";

const getCookie = (name: string) => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? match[2] : null;
};

const useCookie = (cookieName: string) => {
  const [cookieValue, setCookieValue] = useState<string | null>(null);

  useEffect(() => {
    const value = getCookie(cookieName);
    setCookieValue(value);
  }, [cookieName]);

  return cookieValue;
};

export default useCookie;
