import { httpAPIGW } from "@/services/http-clients";
import {
  api2Endpoints,
  postStorageEndPoints
} from "@/services/http-clients/configs";
import { UseQueryOptions, useQuery } from "react-query";

//#region Get costs report

export interface GetCostsReportParams {
  from_date: string;
  to_date: string;
}

export interface GetCostsReportResponse {
  lead_count: number;
  inspection_count: number;
  inspection_cost: number;
  deals_income: number;
  deals_count: number;
  ladders_cost: number;
  ladders_count: number;
}

const getCostsReport = async (params: GetCostsReportParams) => {
  return httpAPIGW<GetCostsReportResponse>({
    method: "GET",
    url: api2Endpoints.EXPENSE_REPORT,
    params: {
      from_date: params.from_date,
      to_date: params.to_date
    }
  }).then((res) => {
    return res.data;
  });
};

const getCostsReportKey = "getCostsReport";

export const useGetCostsReport = (
  params: GetCostsReportParams,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getCostsReport>>,
    // unknown,
    unknown,
    Awaited<ReturnType<typeof getCostsReport>>
  >
) => {
  return useQuery({
    queryKey: [getCostsReportKey, params.from_date, params.to_date],
    staleTime: 0,
    queryFn: () => {
      return getCostsReport(params);
    },
    ...options
  });
};

//#endregion

//#region Get Carpost State

interface GetCarPostStateResponse {
  label: string;
  value: string;
}

const getCarPostState = async () => {
  return httpAPIGW<GetCarPostStateResponse[]>({
    method: "GET",
    url: postStorageEndPoints.CAR_POST_STATES
  }).then((res) => {
    return res.data;
  });
};

const getCarPostStateKey = "getCarPostState";

export const useGetCarPostState = (
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getCarPostState>>,
    // unknown,
    unknown,
    Awaited<ReturnType<typeof getCarPostState>>
  >
) => {
  return useQuery({
    queryKey: [getCarPostStateKey],
    staleTime: 0,
    queryFn: () => {
      return getCarPostState();
    },
    ...options
  });
};

//#endregion
