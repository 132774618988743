/**
 * indicates the url pattern that should be converted to actual url
 * a key/value object of variables that should be replaced in URL
 * @param url
 * @param variables
 */
export const endPointUrlNormalizer = (
  url: string,
  variables: { [key: string]: string }
): string => {
  const parts = url.split("/");
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    if (part.includes(":")) {
      const variableName = part.split(":")[1];
      if (variableName in variables) {
        parts[i] = part.replace(`:${variableName}`, variables[variableName]);
      } else {
        // Variable not found in data, return original URL
        return url;
      }
    }
  }
  return parts.join("/");
};
