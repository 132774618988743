import { Messaging } from "firebase/messaging";
import { getToken, messaging, requestPermission } from "@/firebaseConfig";
import useCookie from "@/hooks/useCookie";
import { FC, ReactNode, useEffect } from "react";
import { usePushToken } from "@/services/api/notification";
import { isTwaApp } from "@/utils/twaUtils";

interface Props {
  children: ReactNode;
}

const vapidKey =
  "BA_oXdLVTT27I70gZTdI0lue7NJ2xSqG6mgtBgfZGoYf1pddqdiwfzQKS0lYr-2rVQgToOTBtAFgPf8n0PKK0HI";

const AppPushNotif: FC<Props> = ({ children }) => {
  const { mutateAsync } = usePushToken();
  const deviceId = useCookie("deviceId");
  const loginToken = useCookie("kpapp-access-token");

  useEffect(() => {
    const requestPermissionAndRegisterToken = async () => {
      try {
        await requestPermission();
        const token = await getToken(messaging as Messaging, { vapidKey });

        if (token && loginToken) {
          await mutateAsync({
            token,
            device_id: deviceId ?? "No Device ID",
            app_id: isTwaApp() ? "P" : "PW"
          });
        } else {
          console.error("No registration token available.");
        }
      } catch (error) {
        console.error("An error occurred while retrieving token.", error);
      }
    };

    requestPermissionAndRegisterToken();
  }, [deviceId, loginToken, mutateAsync]);

  return <>{children}</>;
};

export default AppPushNotif;
