import React, { FC } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SwipeableDrawer,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PhoneEnabledOutlinedIcon from "@mui/icons-material/PhoneEnabledOutlined";
import useDocumentHeight from "@/hooks/useDocumentHeight";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";

import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import { DateCmp } from "@/components/DateCmp/DateCmp";
import { usePostLeasingNote } from "@/services/api/leasing/note";
import * as yup from "yup";
import { Field, FieldProps, Form, Formik } from "formik";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  category: string;
  content: string;
  title: string;
  orderId: string;
}

const NoteModal: FC<Props> = ({ open, showModal, title, orderId }) => {
  const { height: vpHeight, offset } = useDocumentHeight();
  const { mutateAsync, isLoading } = usePostLeasingNote(orderId);

  const createNoteSchema = yup.object().shape({
    category: yup.string().required("تگ یادداشت اجباری است!").nullable(),
    content: yup.string().required("یادداشت اجباری است!").nullable(),
    follow_up_date: yup.string().nullable()
  });

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Box sx={styles.modalBox}>
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <CloseIcon onClick={() => showModal(false)} />
        </Box>
        <Formik
          enableReinitialize
          validationSchema={createNoteSchema}
          initialValues={{
            category: "",
            content: "",
            follow_up_date: undefined
          }}
          onSubmit={(data) => {
            mutateAsync({ ...data, order_id: orderId });
          }}
        >
          {({ handleSubmit, errors, values, setFieldValue }) => (
            <Form
              style={{
                height: "100%"
              }}
              onSubmit={handleSubmit}
            >
              <Stack
                spacing={3}
                sx={{
                  ...styles.formContainer,
                  position: "sticky",
                  bottom: `calc(100dvh - ${vpHeight}px - ${offset}px)`
                }}
              >
                <FormControl>
                  <DateCmp
                    date={values.follow_up_date ?? undefined}
                    title="تاریخ پیگیری"
                    onDateChange={(date) => {
                      setFieldValue("follow_up_date", date);
                    }}
                  />
                </FormControl>
                <Field name="category">
                  {({ field }: FieldProps) => (
                    <FormControl
                      error={!!errors.category}
                      size="small"
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <InputLabel id="note-tag-label">تگ یادداشت</InputLabel>

                      <Select
                        {...field}
                        labelId="note-tag-label"
                        id="note-tag-select"
                        label="تگ یادداشت"
                      >
                        {CATEGOTY_TYPES.map((Item, index) => {
                          return (
                            <MenuItem key={index} value={Item.value}>
                              <Box sx={styles.selectItem}>
                                {Item.icon}
                                {Item.title}{" "}
                              </Box>
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {!!errors.category && (
                        <FormHelperText color="error">
                          {errors.category}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Field name="content">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      sx={{ ...styles.textArea }}
                      label="متن یادداشت"
                      multiline
                      error={!!errors.content}
                      helperText={errors.content}
                      rows={4}
                      name="content"
                    />
                  )}
                </Field>
              </Stack>
              <Box width="100%" height="2rem" />
              <Button
                disabled={
                  Boolean(errors.content) ||
                  Boolean(errors.category) ||
                  isLoading
                }
                type="submit"
                sx={styles.modalBtn}
              >
                {isLoading ? (
                  <CircularProgress size={20} sx={{ color: "#00000050" }} />
                ) : (
                  "ثبت یادداشت"
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </SwipeableDrawer>
  );
};

export default NoteModal;

export const CATEGOTY_TYPES = [
  {
    title: "یادداشت",
    value: "note",
    icon: <DescriptionOutlinedIcon sx={styles.descIcon} />
  },
  {
    title: "تماس بی پاسخ",
    value: "missed-call",
    icon: <PhoneEnabledOutlinedIcon sx={styles.missCallIcon} />
  },
  {
    title: "تماس با مشتری",
    value: "note-call",
    icon: <PhoneEnabledOutlinedIcon sx={styles.customerCallIcon} />
  },
  {
    title: "نیاز به پیگیری",
    value: "need-to-follow-up",
    icon: <FollowTheSignsIcon sx={styles.descIcon} />
  }
];
