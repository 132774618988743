import { httpAPIGW } from "@/services/http-clients";
import { dealersEndPoints } from "@/services/http-clients/configs";
import { AxiosError } from "axios";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions
} from "react-query";
import { toast } from "react-toastify";

export type DealersOffer = {
  id: number;
  car_brand_model: string;
  usage: number;
  address: string;
  car_color: string;
  created_at: string;
  status: "offer" | "auction";
  final_approval_date: null | string;
  auction_time?: string;
  level: number;
  car_brand_model_year: string;
};

export type GetDealersOffersResponse = DealersOffer[];

const getDealersOffers = () => {
  return httpAPIGW<GetDealersOffersResponse>({
    method: "GET",
    url: dealersEndPoints.OFFER_LIST
  }).then((res) => res.data);
};

export const getDealersOffersKey = () => ["dealers-offers"];

export const useGetDealersOffers = (
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getDealersOffers>>,
    AxiosError<unknown>,
    Awaited<ReturnType<typeof getDealersOffers>>,
    ReturnType<typeof getDealersOffersKey>
  >
) => {
  return useQuery({
    ...options,
    queryKey: getDealersOffersKey(),
    queryFn: getDealersOffers
  });
};

export type ApproveDealersOfferParams = { concierge_sale_id: number };
export type ApproveDealersOfferRes = unknown;

const approveDealersOffer = (data: ApproveDealersOfferParams) => {
  return httpAPIGW({
    method: "POST",
    url: dealersEndPoints.APPROVE_OFFER,
    data
  });
};

export const useApproveDealersOffer = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof approveDealersOffer>>,
    AxiosError<unknown>,
    ApproveDealersOfferParams
  >
) => {
  return useMutation({
    ...options,
    onError: (...args) => {
      const error = args?.[0] as AxiosError<string[]>;
      toast.error(error?.response?.data?.[0]);
      options?.onError?.(...args);
    },
    mutationFn: approveDealersOffer
  });
};

export type CancelApprovedDealersOfferParams = ApproveDealersOfferParams;
export type CancelApprovedDealersOfferResponse = unknown;

const cancelApprovedDealersOffer = (data: CancelApprovedDealersOfferParams) => {
  return httpAPIGW({
    method: "POST",
    url: dealersEndPoints.CANCEL_OFFER,
    data
  });
};

export const useCancelApprovedDealersOffer = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelApprovedDealersOffer>>,
    AxiosError<unknown>,
    CancelApprovedDealersOfferParams
  >
) => {
  return useMutation({ ...options, mutationFn: cancelApprovedDealersOffer });
};
