export const convertDateToTile = (
  start: string | undefined,
  end: string | undefined
) => {
  if (start && end) {
    return `${new Date(start).toLocaleDateString("fa", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    })} تا ${new Date(end).toLocaleDateString("fa", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    })}`;
  }
  if (end) {
    return `${new Date(end).toLocaleDateString("fa", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    })}`;
  }
  if (start) {
    return `${new Date(start).toLocaleDateString("fa", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    })}`;
  }
  return "";
};
