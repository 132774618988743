import { httpAPIGW } from "@/services/http-clients";
import { userTraceEndpoints } from "@/services/http-clients/configs";
import { endPointUrlNormalizer } from "@/services/http-clients/utils";
import { UseQueryOptions, useQuery } from "react-query";

//#region Get Ad Performance
export interface GetAdPerformanceResponse {
  viewers_count: 0;
  callers_count: 0;
}

const getAdPerformance = async (car_post_token: string) => {
  return httpAPIGW<GetAdPerformanceResponse>({
    method: "GET",
    url: endPointUrlNormalizer(userTraceEndpoints.CAR_POST_ANALYTICS, {
      token: car_post_token
    })
  }).then((res) => res.data);
};

const getAdPerformanceKey = "getAdPerformance";

export const useGetAdPerformance = (
  car_post_token: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getAdPerformance>>,
    // unknown,
    unknown,
    Awaited<ReturnType<typeof getAdPerformance>>
  >
) => {
  return useQuery({
    queryKey: [getAdPerformanceKey],
    staleTime: 0,
    queryFn: () => {
      return getAdPerformance(car_post_token);
    },
    ...options
  });
};

//#endregion
