import { FC } from "react";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "@/pages/ConciergeSale/requests/tabs/details/styles";
import DownloadIcon from "@mui/icons-material/Download";
import { Documents } from "@/services/api/leasing/detail";

interface Props {
  open: boolean;
  showModal: (item: boolean) => void;
  title: string;
  documents: Documents;
}

const PhotosModal: FC<Props> = ({ open, showModal, title, documents }) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => showModal(false)}
      onOpen={() => showModal(true)}
      PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
    >
      <Stack
        divider={<Divider />}
        spacing={2}
        sx={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          background: "#fff",
          p: "20px"
        }}
      >
        <Box sx={styles.modalTitleArea}>
          <Typography fontWeight={600} fontSize={14}>
            {title}
          </Typography>
          <IconButton onClick={() => showModal(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        {documents.customer_documents &&
          documents.customer_documents.length > 0 && (
            <>
              <Typography fontWeight="bold" fontSize={16}>
                مدارک خریدار
              </Typography>
              {documents.customer_documents.map((item) => (
                <Stack
                  key={item.key}
                  sx={{
                    pb: 1.5,
                    mb: 1.5,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  <Typography sx={{ fontSize: 14 }}>{item.label}</Typography>
                  {item.url ? (
                    <a style={{ color: "#6E6E6E" }} href={item.url} download>
                      <IconButton>
                        <DownloadIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </a>
                  ) : (
                    <Typography sx={{ fontSize: 14, color: "#b4b4b4" }}>
                      فایلی برای دانلود وجود ندارد
                    </Typography>
                  )}
                </Stack>
              ))}
            </>
          )}
        {documents.seller_documents &&
          documents.seller_documents.length > 0 && (
            <>
              <Typography fontWeight="bold" fontSize={16}>
                مدارک فروشنده
              </Typography>
              {documents.seller_documents.map((item) => (
                <Stack
                  key={item.key}
                  sx={{
                    pb: 1.5,
                    mb: 1.5,
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  <Typography sx={{ fontSize: 14 }}>{item.label}</Typography>
                  {item.url ? (
                    <a style={{ color: "#6E6E6E" }} href={item.url} download>
                      <IconButton>
                        <DownloadIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </a>
                  ) : (
                    <Typography sx={{ fontSize: 14, color: "#b4b4b4" }}>
                      فایلی برای دانلود وجود ندارد
                    </Typography>
                  )}
                </Stack>
              ))}
            </>
          )}
        {documents.order_documents && documents.order_documents.length > 0 && (
          <>
            <Typography fontWeight="bold" fontSize={16}>
              مدارک سفارش
            </Typography>
            {documents.order_documents.map((item) => (
              <Stack
                key={item.key}
                sx={{
                  pb: 1.5,
                  mb: 1.5,
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <Typography sx={{ fontSize: 14 }}>{item.label}</Typography>
                {item.url ? (
                  <a style={{ color: "#6E6E6E" }} href={item.url} download>
                    <IconButton>
                      <DownloadIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  </a>
                ) : (
                  <Typography sx={{ fontSize: 14, color: "#b4b4b4" }}>
                    فایلی برای دانلود وجود ندارد
                  </Typography>
                )}
              </Stack>
            ))}
          </>
        )}
      </Stack>
    </SwipeableDrawer>
  );
};

export default PhotosModal;
