import React, { FC, useCallback, useEffect, useRef } from "react";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useRootLayout } from "@/hooks/useRootLayout";
import RequestCard from "@/pages/leasing/requests/components/Card";
import {
  useGetLeasingOrders,
  useGetOrderDynamicStates
} from "@/services/api/leasing/requests";
import { useLocation, useNavigate } from "react-router";
import SadLeasingIcon from "@/assets/icons/sad-leasing-icon.svg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import Filter from "./components/Filter";
import { useSearchParams } from "react-router-dom";

// saving current scrolled position by pathname of the page.
const savedScrollPosition: [string | null, number | null] = [null, null];

const Requests: FC = () => {
  const { setHeaderTitle } = useRootLayout();
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const { pathname, search } = useLocation();

  const containerRef = useRef<HTMLDivElement>(null);

  const querySearch = params.get("search") || "";
  const queryStatus = params.getAll("status");
  const querycategory = params.get("category") || "";
  const queryprovider = params.getAll("provider") || "";
  const queryCities = params.getAll("city") || [];
  const queryStartOrder = params.get("orderStart") || undefined;
  const queryEndOrder = params.get("orderEnd") || undefined;
  const queryStartNote = params.get("noteStart") || undefined;
  const queryEndNote = params.get("noteEnd") || undefined;
  const followUpDate = params.get("follow_up_date") || undefined;
  const queyIsDocumentsRejected =
    params.get("isDocumentsRejected") || undefined;
  const queryChannel = params.get("channel") || undefined;

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useGetLeasingOrders(
      queryStatus,
      1,
      querySearch,
      querycategory,
      queryprovider,
      queryCities,
      queryStartOrder,
      queryEndOrder,
      queryStartNote,
      queryEndNote,
      queyIsDocumentsRejected,
      queryChannel,
      followUpDate
    );
  const { data: dynamicStates } = useGetOrderDynamicStates();

  const asPath = `${pathname}${search}`;

  const saveCurrentPosition = useCallback(() => {
    const containerElm = containerRef.current;
    if (containerElm) {
      savedScrollPosition[0] = asPath;
      savedScrollPosition[1] = containerElm.scrollTop;
    }
  }, [asPath]);

  useEffect(() => {
    const containerElm = containerRef.current;
    if (containerElm) {
      if (
        typeof savedScrollPosition[0] === "string" &&
        savedScrollPosition[0] === asPath &&
        typeof savedScrollPosition[1] === "number"
      ) {
        containerElm.scrollTo({
          top: savedScrollPosition[1],
          left: 0
        });
      } else {
        containerElm.scrollTo({
          top: 0,
          left: 0
        });
      }
    }
  }, [asPath]);

  useEffect(() => {
    if (setHeaderTitle) {
      setHeaderTitle("لیست درخواست های لیزینگ");
    }
  }, [setHeaderTitle]);

  if (isLoading) {
    return (
      <Stack
        width="100%"
        height="400px"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size="50px" />
      </Stack>
    );
  }

  return (
    <>
      <Filter />
      <Stack
        ref={containerRef}
        sx={{
          width: "100%",
          px: 2,
          pt: 2,
          pb: 7,
          height: "100vh",
          overflowY: "scroll",
          background: "#FAFAFA"
        }}
      >
        {data?.pages.map((page, index) => (
          <React.Fragment key={index}>
            {page.items.length ? (
              page.items.map((item) => (
                <RequestCard
                  providerId={item?.parent_id}
                  onClick={() => {
                    saveCurrentPosition();
                    navigate(`/leasing/requests/${item.id}`);
                  }}
                  key={item.id}
                  instalment_month_count={item.instalment_month_count}
                  id={item.id}
                  full_name={item.customer ? item.customer.full_name : "-"}
                  created_at={item.created_at}
                  provider={item?.provider?.name_fa ?? "-"}
                  amount={item.amount}
                  isRejected={item.is_rejected}
                  status_fa={
                    dynamicStates?.find((st) => st?.id === item.state.id)
                      ?.name_fa || "-"
                  }
                />
              ))
            ) : (
              <Stack
                sx={{ justifyContent: "center", alignItems: "center", mt: 10 }}
              >
                <img
                  style={{ width: "80px" }}
                  src={SadLeasingIcon}
                  alt="sad leasing icon"
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    mt: 2,
                    fontWeight: 500,
                    lineHeight: "1.7",
                    fontSize: 17
                  }}
                >
                  متأسفیم!
                  <br />
                  لیدی وجود ندارد.
                </Typography>
              </Stack>
            )}
          </React.Fragment>
        ))}

        {hasNextPage && (
          <Button
            sx={{ mb: 2 }}
            variant="outlined"
            disabled={isFetchingNextPage}
            onClick={() => fetchNextPage()}
          >
            {isFetchingNextPage ? (
              <CircularProgress size={20} sx={{ color: "#00000050" }} />
            ) : (
              "مشاهده سایر درخواست‌ها"
            )}
          </Button>
        )}
      </Stack>

      <Button
        sx={{ position: "absolute", bottom: 30, right: 17, borderRadius: 30 }}
        onClick={() => navigate("/leasing/submit-order")}
      >
        <AddOutlinedIcon sx={{ fontSize: 22, mr: 0.5, cursor: "pointer" }} />
        درخواست جدید
      </Button>
    </>
  );
};

export default Requests;
